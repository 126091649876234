.Painting {
  padding: 0rem !important;
}

.canvas {
  height: 100%;
  width: 100%;
  padding: .5rem;
  background-color: white;
  background-size: cover;
}

.preload {
  height: 0;
  width: 0;
  position: absolute;
  top: -999px; right: -999px;
  visibility: hidden;
}

.painting-0  { background-image: url('/paintings/00.jpg'); }
.painting-1  { background-image: url('/paintings/01.jpg'); }
.painting-2  { background-image: url('/paintings/02.jpg'); }
.painting-3  { background-image: url('/paintings/03.jpg'); }
.painting-4  { background-image: url('/paintings/04.jpg'); }
.painting-5  { background-image: url('/paintings/05.jpg'); }
.painting-6  { background-image: url('/paintings/06.jpg'); }
.painting-7  { background-image: url('/paintings/07.jpg'); }
.painting-8  { background-image: url('/paintings/08.jpg'); }
.painting-9  { background-image: url('/paintings/09.jpg'); }

.painting-10 { background-image: url('/paintings/10.jpg'); }
.painting-11 { background-image: url('/paintings/11.jpg'); }
.painting-12 { background-image: url('/paintings/12.jpg'); }
.painting-13 { background-image: url('/paintings/13.jpg'); }
.painting-14 { background-image: url('/paintings/14.jpg'); }
.painting-15 { background-image: url('/paintings/15.jpg'); }
.painting-16 { background-image: url('/paintings/16.jpg'); }
.painting-17 { background-image: url('/paintings/17.jpg'); }
.painting-18 { background-image: url('/paintings/18.jpg'); }
.painting-19 { background-image: url('/paintings/19.jpg'); }

.painting-20 { background-image: url('/paintings/20.jpg'); }
.painting-21 { background-image: url('/paintings/21.jpg'); }
.painting-22 { background-image: url('/paintings/22.jpg'); }
.painting-23 { background-image: url('/paintings/23.jpg'); }
.painting-24 { background-image: url('/paintings/24.jpg'); }
.painting-25 { background-image: url('/paintings/25.jpg'); }
.painting-26 { background-image: url('/paintings/26.jpg'); }
.painting-27 { background-image: url('/paintings/27.jpg'); }
.painting-28 { background-image: url('/paintings/28.jpg'); }
.painting-29 { background-image: url('/paintings/29.jpg'); }

.painting-30 { background-image: url('/paintings/30.jpg'); }
.painting-31 { background-image: url('/paintings/31.jpg'); }
.painting-32 { background-image: url('/paintings/32.jpg'); }
.painting-33 { background-image: url('/paintings/33.jpg'); }
.painting-34 { background-image: url('/paintings/34.jpg'); }
.painting-35 { background-image: url('/paintings/35.jpg'); }
.painting-36 { background-image: url('/paintings/36.jpg'); }
