@import url('https://fonts.googleapis.com/css?family=Eczar');


@font-face {
  font-family: general;
  src: url('/general/General-Regular.eot');
  src: url('/general/General-Regular.woff2') format('woff2'), url('/general/General-Regular.woff') format('woff');
}

* {
  box-sizing: border-box;
  color: red;
}

/* lol */
* {
  cursor: url("/nat942.ani"), url("/nat942.png"), auto !important;
}

body {
  padding: 0;
  margin: 0;
  font-family: general, serif;
  color: red;
  font-size: 15px;
}

button {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none; /* so so sorry */
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-align: left;
}

a, button {
  text-decoration: none;
}

a:hover, button:hover { text-decoration: underline; }
a:active, button:active { color: blue; }

.Garden {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;

  background-color: #ffffff;

}

.box {
  padding: 0.25rem;
}

/*  ~ ~ ~ l a n d s c a p e ~ ~ ~  */

.box.sq {
  width: 100vh;
  height: 100vh;
  /* max-width: calc(100vw - 200px);
  max-width: calc(100vw - 200px); */
}

.box.other {
  width: calc(100vw - 100vh);
  height: 100vh;
  min-width: 350px;
}

/*  ~ ~ ~ p o r t r a i t ~ ~ ~  */

@media all and (orientation:portrait) {
  .Garden {
    flex-flow: column-reverse;
  }
  .box.sq {
    width: 100vw;
    height: 100vw;
    /* max-width: calc(100vh - 200px);
    max-width: calc(100vh - 200px); */
  }
  .box.other {
    width: 100vw;
    height: calc(100vh - 100vw);
    min-height: 150px;
  }
}
