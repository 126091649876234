/* layout style mostly comes from 'other' class */

.Sampler {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.thing {
  cursor: ne-resize !important;
}

.knobs {
  max-width: 208px;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
}

.checkbox {
  margin: 0.0625rem;
}

.param-slider {
  width: 94px;
}

label {
  width: 19px;
  text-align: right;
}

.morsels {
  align-self: flex-end;
}
