.thing {
  display: inline-block;
  padding: 0;
}

.morsels {
  cursor: ne-resize !important;
}

.Activities {
  padding: 1rem !important;
}

/*  ~ ~ ~ small ~ ~ ~  */

.Activities {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.Activities .top {
  align-self: start;
}

.Activities .mid {
  align-self: center;
}

.Activities .morsels {
  align-self: flex-end;
}
